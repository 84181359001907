import React from "react";
import { Link } from "react-router-dom"; 

function ServiceC(){
    return(
        <>
             

            <div className="container-fluid services py-5 mb-5">
            <div className="container">
                <div className="text-center mx-auto pb-5 wow fadeIn" data-wow-delay=".3s" style={{maxWidth : '600px'}}>
                    <h5 className="text-primary">Our Services</h5>
                    <h1 className="text-white">Services Built Specifically For Your Business</h1>
                </div>
                <div className="row g-5 services-inner">
                    <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".3s">
                        <div className="services-item bg-light">
                            <div className="p-4 text-center services-content">
                                <div className="services-content-icon">
                                    <i className="fa fa-code fa-7x mb-4 text-primary"></i>
                                    <h4 className="mb-3">Web Design</h4>
                                    <p className="mb-4">We specialize in creating visually appealing and functionally seamless websites that adapt flawlessly to every device. </p> 
                                    <Link to="/Webdesign" className="btn btn-secondary text-white px-5 py-3 rounded-pill">Read More</Link>
                                </div> 
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".5s">
                        <div className="services-item bg-light">
                            <div className="p-4 text-center services-content">
                                <div className="services-content-icon">
                                    <i className="fa fa-file-code fa-7x mb-4 text-primary"></i>
                                    <h4 className="mb-3">Web Development</h4>
                                    <p className="mb-4">We specialize in creating dynamic, user-friendly, and scalable web applications tailored to meet your unique business needs. </p> 
                                    <Link to="/WebDevelopment" className="btn btn-secondary text-white px-5 py-3 rounded-pill">Read More</Link> 
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".7s">
                        <div className="services-item bg-light">
                            <div className="p-4 text-center services-content">
                                <div className="services-content-icon">
                                    <i className="fa fa-external-link-alt fa-7x mb-4 text-primary"></i>
                                    <h4 className="mb-3">UI/UX Design</h4>
                                    <p className="mb-4">We understand the pivotal role of user interface (UI) and user experience (UX) design in creating successful software applications. </p>
                                    <Link to="/UiUxDesign" className="btn btn-secondary text-white px-5 py-3 rounded-pill">Read More</Link>
                                </div>
                            </div>
                        </div> 
                    </div>
                    <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".3s">
                        <div className="services-item bg-light">
                            <div className="p-4 text-center services-content">
                                <div className="services-content-icon">
                                    <i className="fab fa fa-mobile fa-7x mb-4 text-primary"></i> 
                                    <h4 className="mb-3">Mobile Applications</h4> 
                                    <p className="mb-4">We build interactive mobile apps to extend your reach to potential customers & maximize conversions. Our platform apps designed for the iPhone, Blackberry, Android etc. </p>
                                    <Link to="/MobileApp" className="btn btn-secondary text-white px-5 py-3 rounded-pill">Read More</Link>
                                </div> 
        
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".5s">
                        <div className="services-item bg-light">
                            <div className="p-4 text-center services-content">
                                <div className="services-content-icon">
                                    <i className="fa fa-envelope-open fa-7x mb-4 text-primary"></i>
                                    <h4 className="mb-3">Digital Marketing</h4>
                                    <p className="mb-4">We specialize in crafting data-driven strategies to enhance your online visibility, engage your target audience, and drive measurable results.</p> 
                                    <Link to="/DigitalMarketing" className="btn btn-secondary text-white px-5 py-3 rounded-pill">Read More</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".7s">
                        <div className="services-item bg-light">
                            <div className="p-4 text-center services-content">
                                <div className="services-content-icon">
                                    <i className="fas fa-laptop fa-7x mb-4 text-primary"></i>
                                    <h4 className="mb-3">Software Development</h4>
                                    <p className="mb-4">We pride ourselves on being at the forefront of innovation in the ever-evolving landscape of programming. </p> 
                                    <Link to="/SoftwareDevelopment" className="btn btn-secondary text-white px-5 py-3 rounded-pill">Read More</Link>
                                </div>  
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
        </div> 
        </>
    );
}

export default ServiceC;