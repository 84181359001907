

function AboutC(){
    return(
        <>
           
            {/* <div className="container-fluid bg-secondary py-5">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 wow fadeIn" data-wow-delay=".1s">
                        <div className="d-flex counter">
                            <h1 className="me-3 text-primary counter-value">99</h1>
                            <h5 className="text-white mt-1">Success in getting happy customer</h5>
                        </div>
                    </div>
                    <div className="col-lg-3 wow fadeIn" data-wow-delay=".3s">
                        <div className="d-flex counter">
                            <h1 className="me-3 text-primary counter-value">25</h1>
                            <h5 className="text-white mt-1">Thousands of successful business</h5>
                        </div>
                    </div>
                    <div className="col-lg-3 wow fadeIn" data-wow-delay=".5s">
                        <div className="d-flex counter">
                            <h1 className="me-3 text-primary counter-value">120</h1>
                            <h5 className="text-white mt-1">Total clients who love Code IT Solutions</h5>
                        </div>
                    </div>
                    <div className="col-lg-3 wow fadeIn" data-wow-delay=".7s">
                        <div className="d-flex counter">
                            <h1 className="me-3 text-primary counter-value">5</h1>
                            <h5 className="text-white mt-1">Stars reviews given by satisfied clients</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div> */}
        
        <div className="container-fluid py-5 my-5">
            <div className="container pt-5">
                <div className="row g-5">
                    <div className="col-lg-5 col-md-6 col-sm-12 wow fadeIn" data-wow-delay=".3s">
                        <div className="h-100 position-relative">
                            <img src="img/about2.jpg" className="img-fluid w-75 rounded" alt="" style={{marginBottom: '25%'}} />
                            <div className="position-absolute w-75" style={{top: '25%', left: '25%'}}>
                                <img src="img/about1.jpg" className="img-fluid w-100 rounded" alt="" />
                            </div> 
                        </div>
                    </div>
                    <div className="col-lg-7 col-md-6 col-sm-12 wow fadeIn" data-wow-delay=".5s">
                        <h5 className="text-primary">About Us</h5>
                        <h2 className="mb-4"> Code IT Solutions It's Innovative Agency</h2>  
                        <p>Code IT Solutions is a cutting-edge and innovative agency at the forefront of technological advancement. Specializing in providing comprehensive IT solutions, we pride ourselves on delivering tailored services that address the unique needs of our clients. Our team of skilled professionals is dedicated to staying ahead of the curve, leveraging the latest technologies to drive efficiency and maximize productivity for businesses across various industries. </p>
                        <p className="mb-4">With a commitment to innovation, we strive to create and implement groundbreaking solutions that not only meet but exceed the expectations of our clients. </p>  
                        {/* <a href="" className="btn btn-secondary rounded-pill px-5 py-3 text-white">View More</a> */} 
                    </div>
                </div>
            </div>
        </div>

       
        </> 
    );
}

export default AboutC;