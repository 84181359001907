import { useState } from "react";
import { NavLink, Link } from "react-router-dom";  
import DropdownMenu from "./DropdownMenu";

function Header(){
    const [isCollapsed, setIsCollapsed] = useState(true);

    const toggleNavbar = () => {
        setIsCollapsed(!isCollapsed);
    };

    const closeNavbar = () => {
        setIsCollapsed(true); // Close the navbar after a link is clicked
    };
    return(
        <> 
            {/* <div id="spinner" className="show position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
                <div className="spinner-grow text-primary" role="status"></div>
            </div> */}
            
            <div className="container-fluid bg-dark py-2 d-none d-md-flex">
            <div className="container">
                <div className="d-flex justify-content-between topbar">
                <div className="top-info">
                    <small className="me-3 text-white-50"><a href><i className="fas fa-map-marker-alt me-2 text-secondary" /></a>E45 Krishna Park New Delhi</small>
                    <small className="me-3 text-white"><a href="mailto:info@codeitsolutionshub.com" className="text-white-50"><i className="fas fa-envelope me-2 text-secondary" /> info@codeitsolutionshub.com</a></small>
                </div>
                <div id="note" className="text-secondary d-none d-xl-flex">
                    <small>Note : We help you to Grow your Business</small>
                </div>
                <div className="top-link"> 
                    <a href className="bg-light nav-fill btn btn-sm-square rounded-circle"><i className="fab fa-facebook-f text-primary" /></a>
                    <a href className="bg-light nav-fill btn btn-sm-square rounded-circle"><i className="fab fa-twitter text-primary" /></a>
                    <a href className="bg-light nav-fill btn btn-sm-square rounded-circle"><i className="fab fa-instagram text-primary" /></a>
                    <a href className="bg-light nav-fill btn btn-sm-square rounded-circle me-0"><i className="fab fa-linkedin-in text-primary" /></a>
                </div>
                </div>
            </div>
            </div>

            
            <div className="container-fluid bg-primary" id="navbarContainer">
                <div className="container">
                    <nav className="navbar navbar-dark navbar-expand-lg py-0"> 
                        <a href="/" className="navbar-brand"> 
                            <h2 className="text-white fw-bold d-block"><img src="img/codeitsolutions_logo.png" alt="" style={{width:'60px', marginLeft: '-24px'}}/>IT<span className="text-secondary">Solutions</span> </h2>
                        </a>  
                        <button
                            type="button"
                            className={`navbar-toggler me-0 ${isCollapsed ? "collapsed" : ""}`}
                            data-bs-toggle="collapse"
                            data-bs-target="#navbarCollapse"
                            onClick={toggleNavbar}
                        >
                            <span className="toggler-icon top-bar"></span>
                            <span className="toggler-icon middle-bar"></span>
                            <span className="toggler-icon bottom-bar"></span>
                        </button>
                        <div className={`collapse navbar-collapse bg-transparent ${isCollapsed ? "" : "show"}`} id="navbarCollapse">
                            <div className="navbar-nav ms-auto mx-xl-auto p-0">
                                <NavLink onClick={closeNavbar} className={`nav-item nav-link ${(navData) =>navData.isActive ? "active" : ""}`} to="/">Home</NavLink>
                                <NavLink className={`nav-item nav-link ${(navData) =>navData.isActive ? "active" : ""}`} to="/about">About Us</NavLink> 
                                <NavLink onClick={closeNavbar} className={`nav-item nav-link ${(navData) =>navData.isActive ? "active" : ""}`} to="/service">Services</NavLink>
                                <NavLink onClick={closeNavbar} className={`nav-item nav-link ${(navData) =>navData.isActive ? "active" : ""}`} to="/projects">Projects</NavLink>  
                                {/* <DropdownMenu /> */}
                               <NavLink onClick={closeNavbar} className={`nav-item nav-link ${(navData) =>navData.isActive ? "active" : ""}`} to="/contact">Contact Us</NavLink> 
                            </div>
                        </div>  
                        <div className="d-none d-xl-flex flex-shirink-0">
                            <div id="phone-tada" className="d-flex align-items-center justify-content-center me-4">
                                <a href="" className="position-relative animated tada infinite">
                                            <i className="fa fa-phone-alt text-white fa-2x"></i>
                                            <div className="position-absolute" style={{top: '-7px', left: '20px'}}> 
                                                <span><i className="fa fa-comment-dots text-secondary"></i></span> 
                                            </div>
                                        </a>
                            </div>
                            <div className="d-flex flex-column pe-4 border-end">
                                <span className="text-white">Have any questions?</span> 
                                {/* <span className="text-secondary">Call: +91 7992249312</span>   */} 
                                <span className="text-secondary">Call: <a href="tel: +91 7992249312" style={{color: '#FDA403'}}>+91 7992249312</a></span>    
                            </div> 
                          <div className="d-flex align-items-center justify-content-center ms-4 ">
                                <a href><i className="bi bi-search text-white fa-2x"></i> </a>
                            </div>    
                        </div>
                    </nav>
                </div>
            </div> 

            {/* <!--social buttons start--> */}
            <div className="chat_to_hb">
            <a href="https://api.whatsapp.com/send?phone=917992249312&text=Code IT Solutions." target="_blank">
                <img src="img/whatsapp-logo.png" alt="Code IT Solutions" title="Code IT Solutions" />
            </a>
            </div>

            <div className="chat_to_hcall"> 
            <a href="tel:917992249312">
                <img src="img/call.png" alt="call" title="call" />
            </a>
            </div>
  

            {/* <!--social buttons end--> */}   


            <button type="button" class="btn request-quote-btn text-white-50" data-bs-toggle="modal" data-bs-target="#exampleModal">Request a Quote</button>

            {/* Modal */}
            <div className="modal fade" id="exampleModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-xl">
                <div className="modal-content quote-modal-wrapper">
                <div className="modal-header">
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                </div>
                <div className="modal-body modal-main-wrapper">
                        <div className="container-fluid">
                            <div className="row mb-5 main-row-wrapper">
                            <div className="col-lg-6 col-md-6 col-sm-12 wow fadeIn" data-wow-delay=".3s">
                                <div className="h-100 position-relative">
                                <h2 className="modal-heading">Let's Discuss <strong>Your Idea</strong></h2>
                                <p className="modal-heading-text">Feel free to fill out the form, and our team will get in touch within <strong>24 hours.</strong></p>
                                <img src="img/request-q1.jpg" className="img-fluid modal-img w-75 rounded" alt style={{marginBottom: '25%'}} />
                                <div className="position-absolute w-75" style={{top: '25%', left: '25%'}}>
                                    <img src="img/request-q2.jpg" className="img-fluid modal-img w-100 rounded" alt />
                                </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 wow fadeIn" data-wow-delay=".5s">
                                <form className="quotes_form bg-white p-5 rounded">
                                    {/* <div className="mb-3">
                                        <label htmlFor="name" className="form-label">Name <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control" id="name" placeholder="Enter name here" />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="email" className="form-label">Email address <span className="text-danger">*</span></label>
                                        <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email here" />
                                        <div id="emailHelp" className="form-text">We'll never share your email with anyone else.</div>
                                    </div>

                                    <div className="mb-3">
                                        <label htmlFor="phone" className="form-label">Phone<span className="text-danger">*</span></label>
                                    
                                        <div class="input-group mb-3"> 
                                            <button class="btn btn-secondary custom-btn dropdown-toggle selected" type="button" data-bs-toggle="dropdown">+91 
                                            </button> 
                                            <ul class="dropdown-menu"> 
                                                <li> 
                                                    <a class="dropdown-item" href="#"> 
                                                        +91 
                                                    </a> 
                                                </li> 
                                                
                                            </ul> 
                                            <input type="text" class="form-control" placeholder="Enter Phone here" /> 
                                        </div> 

                                    </div>

                                    <div className="mb-3">
                                        <label htmlFor="project" className="form-label">Tell us about your project</label> 
                                        <textarea class="form-control" id="#" rows="3" placeholder="Tell us about your project"></textarea>
                                    </div> 

                                    <div className="mb-3">
                                        <div className="position-relative mb-3 badge_wrap">
                                        <em className="badge-icon">
                                            <img src="img/security-badge.svg" alt="security badge" width={23} height={28} /></em>
                                        Your Ideas are Fully Protected By Our <strong>Non Disclosure Agreement (NDA)</strong>
                                        </div> 
                                    </div>

                                    <button type="submit" className="btn btn-primary cta_btn">Submit</button> */}

                                    <div className="mb-4">
                                        <label htmlFor="name" className="form-label text-white">Name <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control border-0 py-3" placeholder="Your Name" required="required" />
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="email" className="form-label text-white">Email <span className="text-danger">*</span></label>
                                        <input type="email" className="form-control border-0 py-3" placeholder="Your Email" required="required" />
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="phone" className="form-label text-white">Phone <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control border-0 py-3" placeholder="Your Phone" required="required" />
                                    </div> 
                                    <div className="mb-4"> 
                                        <label htmlFor="project" className="form-label text-white">Tell us about your project</label>
                                        <textarea className="w-100 form-control border-0 py-3" rows="3" cols="6" placeholder="Message"></textarea>
                                    </div>

                                    <div className="mb-4">
                                        <div className="position-relative mb-3 badge_wrap">
                                        <em className="badge-icon">
                                            <img src="img/security-badge.svg" alt="security badge" width={23} height={28} /></em>
                                            <p className="text-white">Your Ideas are Fully Protected By Our <strong>Non Disclosure Agreement (NDA)</strong></p>
                                        </div>  
                                    </div>

                                    {/* <div className="mb-4">
                                        <p className="text-white">Robot Captcha Here</p> 
                                    </div>  */} 

                                    <div className="text-start">
                                        {/* <button type="submit" className="btn bg-warning text-white py-2 px-4">Send Message</button> */}

                                        <input type="submit" className="btn bg-warning text-white py-2 px-4" />
                                    </div>

                                </form>

                            </div>
                            </div>
                        </div>
                   
                {/* About End */}

                {/* form create */}

                </div>
                
                </div>
            </div>
            </div>



        </>
    );
}

export default Header;  