import React from "react";
import { Link } from "react-router-dom";
import ProjectC from "../Components/ProjectC";

function SoftwareDevelopment() {
  return (
    <> 
      <div className="container-fluid page-header py-5">
          <div className="container text-center py-5">
              <h1 className="display-2 text-white mb-4 animated slideInDown">Software Development</h1>
              <nav aria-label="breadcrumb animated slideInDown">
                  <ol className="breadcrumb justify-content-center mb-0">
                      <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                      <li className="breadcrumb-item" aria-current="page" style={{color: '#FDA403 '}}>Software Development</li>
                  </ol>
              </nav> 
          </div>
      </div> 
      <div className="container-fluid py-5 mb-5">
        <div className="container">
          <div className="text-center mx-auto pb-5 wow fadeIn" data-wow-delay=".3s" style={{ maxWidth: '600px' }}>
            <h5 className="text-primary">Software Development</h5>
            <h1 className="text-dark">Building Custom Software Solutions for Your Business</h1>
          </div>
          <div className="row g-5">
            <div className="col-12 wow fadeIn" data-wow-delay=".3s">
              <div className="bg-light p-5">
                <h4 className="mb-3">Our Software Development Services</h4>
                <p>We specialize in delivering custom software solutions that meet the unique needs of your business. From initial concept to deployment and maintenance, we ensure high performance and scalability in every solution we build.</p>
                <p>We offer:</p>
                <ul>
                  <li>Custom Software Development</li>
                  <li>Enterprise Software Solutions</li>
                  <li>Software Integration Services</li>
                  <li>Software Maintenance & Support</li>
                </ul>
                <Link to="/contact" className="btn btn-primary text-white px-5 py-3 rounded-pill">Get in Touch</Link>
              </div>
            </div>
          </div>
        </div>
        <ProjectC />  
      </div>
    </>
  );
}

export default SoftwareDevelopment;
