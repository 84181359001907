import { Link } from "react-router-dom";
import { useEffect, useState } from "react";

function Footer() {
  const [isVisible, setIsVisible] = useState(false);

  // Handle scroll events to toggle button visibility
  const handleScroll = () => {
    setIsVisible(window.scrollY > 300);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div className="container-fluid footer bg-dark wow fadeIn" data-wow-delay=".3s">
        <div className="container pt-5 pb-4">
          <div className="row g-5">
            <div className="col-lg-3 col-md-6">
              <a href="/">
                <h2 className="text-white fw-bold d-block">
                  <img src="img/codeitsolutions_logo.png" alt="" style={{ width: '60px' }} />
                  IT<span className="text-secondary">Solution</span>
                </h2>
              </a>
              <p className="mt-4 text-light text-justify">
                Specializing in providing comprehensive IT solutions, we pride ourselves on delivering tailored services that address the unique needs of our clients.
              </p>
              <div className="d-flex hightech-link">
                <Link to="javascript:void(0)" className="btn-light nav-fill btn btn-square rounded-circle me-2">
                  <i className="fab fa-facebook-f text-primary"></i>
                </Link>
                <Link to="javascript:void(0)" className="btn-light nav-fill btn btn-square rounded-circle me-2">
                  <i className="fab fa-twitter text-primary"></i>
                </Link>
                <Link to="javascript:void(0)" className="btn-light nav-fill btn btn-square rounded-circle me-2">
                  <i className="fab fa-instagram text-primary"></i>
                </Link>
                <Link to="javascript:void(0)" className="btn-light nav-fill btn btn-square rounded-circle me-0">
                  <i className="fab fa-linkedin-in text-primary"></i>
                </Link>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <Link to="#" className="h3 text-secondary">Short Link</Link>
              <div className="mt-4 d-flex flex-column short-link">
                <Link to="/about" className="mb-2 text-white">
                  <i className="fas fa-angle-right text-secondary me-2"></i>About us
                </Link>
                <Link to="/contact" className="mb-2 text-white">
                  <i className="fas fa-angle-right text-secondary me-2"></i>Contact us
                </Link>
                <Link to="/service" className="mb-2 text-white">
                  <i className="fas fa-angle-right text-secondary me-2"></i>Our Services
                </Link>
                <Link to="/projects" className="mb-2 text-white">
                  <i className="fas fa-angle-right text-secondary me-2"></i>Our Projects
                </Link>
              </div>
            </div>

            <div className="col-lg-6 col-md-6"> 
              <a href="#" className="h3 text-secondary">Contact Us</a>
              <div className="text-white mt-4 d-flex flex-column contact-link">
                <a href="javascript:void(0)" className="pb-3 text-light border-bottom">
                  <i className="fas fa-map-marker-alt text-secondary me-2"></i>
                  E45 krishna Park Khanpur Devli,&nbsp;New Delhi - 110062
                </a>
                <a href="tel: +91 7992249312" className="py-3 text-light border-bottom">
                  <i className="fas fa-phone-alt text-secondary me-2"></i> +91 7992249312
                </a>
                <a href="mailto: info@codeitsolutionshub.com" className="py-3 text-light border-bottom">
                  <i className="fas fa-envelope text-secondary me-2"></i> info@codeitsolutionshub.com
                </a>
              </div>
            </div>
          </div>

          <hr className="text-light mt-5 mb-4" />

          <div className="row">
            <div className="col-md-12 text-center">
              <span className="text-light">
                <i className="fas fa-copyright text-secondary me-2"></i> All right reserved. Designed & Developed By : <a href="/" className="text-secondary">Code IT Solutions</a>
              </span>
            </div>
          </div>
        </div>
      </div>

      {/* Back to top button */}
      <a
        href="#"
        className={`btn btn-secondary btn-square rounded-circle back-to-top ${isVisible ? 'block' : 'd-none'}`} 
      >
        <i className="fa fa-arrow-up text-white"></i>
      </a>
    </>
  );
}

export default Footer;
