import React from "react";
import Home from "./Pages/Home";
import About from "./Pages/About";
import Service from "./Pages/Service";
import Projects from "./Pages/Projects";
import Contact from "./Pages/Contact";

import { Routes, Route } from "react-router-dom";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import ScrollToTop from "./Components/ScrollToTop";
import Webdesign from "./Pages/Webdesign";
import WebDevelopment from "./Pages/WebDevelopment";
import UiUxDesign from "./Pages/UiUxDesign";
import MobileApp from "./Pages/MobileApp";
import DigitalMarketing from "./Pages/DigitalMarketing";
import SoftwareDevelopment from "./Pages/SoftwareDevelopment";
import PayNow from "./Pages/PayNow";

function App() {
  return (
    <div className="App">
      <Header />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/service" element={<Service />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/Webdesign" element={<Webdesign />} />
        <Route path="/WebDevelopment" element={<WebDevelopment />} />
        <Route path="/UiUxDesign" element={<UiUxDesign />} />
        <Route path="/MobileApp" element={<MobileApp />} />
        <Route path="/DigitalMarketing" element={<DigitalMarketing />} />
        <Route path="/SoftwareDevelopment" element={<SoftwareDevelopment />} />
        <Route path="/PayNow" element={<PayNow />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
