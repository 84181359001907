import React, { useState } from "react";
import { Link } from "react-router-dom";
import '../Pages/PayNow.css';

function PayNow() {
  const [showBankDetails, setShowBankDetails] = useState(false);
  const [showMobileDetails, setShowMobileDetails] = useState(false);

  const toggleBankDetails = () => {
    setShowBankDetails(!showBankDetails);
    setShowMobileDetails(false);
  };

  const toggleMobileDetails = () => {
    setShowMobileDetails(!showMobileDetails);
    setShowBankDetails(false);
  };

  return (
    <>
      <div className="container-fluid page-header py-5">
        <div className="container text-center py-5">
          <h1 className="display-2 text-white mb-4 animated slideInDown">Pay Now</h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb justify-content-center mb-0">
              <li className="breadcrumb-item"><Link to="/">Home</Link></li>
              <li className="breadcrumb-item active" aria-current="page" style={{ color: '#FDA403' }}>Pay Now</li>
            </ol>
          </nav>
        </div>
      </div>

      <div className="container-fluid py-5 mb-5">
        <div className="container">
          <div className="text-center mx-auto pb-5 wow fadeIn" data-wow-delay=".3s" style={{ maxWidth: '600px' }}>
            <h5 className="text-primary">Payment Services</h5>
            <h1 className="text-dark">Secure and Fast Online Payments</h1>
          </div>
          <div className="row g-5">
            <div className="col-12 wow fadeIn" data-wow-delay=".3s">
              <div className="bg-light p-5">
                <h4 className="mb-3">Our Payment Services</h4>
                <ul>
                  <li> <i class="fa fa-hand-o-right" aria-hidden="true"></i>
                    <button className="btn btn-link" onClick={toggleBankDetails}>
                      Bank Transfers
                    </button>
                  </li>
                  <li>
                    <button className="btn btn-link" onClick={toggleMobileDetails}>
                      Mobile Payment Options
                    </button>
                  </li>
                </ul>

                {showBankDetails && (
                  <div className="bank-form mt-5 text-center">
                    <table className="table bank-table mx-auto">
                      <thead className="bank-table-header heading">
                        <tr>
                          <th>Details</th>
                          <th>Information</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Bank Name</td>
                          <td>HDFC</td>
                        </tr>
                        <tr>
                          <td>Account Number</td>
                          <td>50100285063995</td>
                        </tr>
                        <tr>
                          <td>IFSC Code</td>
                          <td>HDFC0001351</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                )}

                {showMobileDetails && (
                  <div className="mobile-form mt-5">
                    <div className="qr-container">
                      <div className="qr-item">
                        <p>PhonePe QR</p>
                        <img src="img/phonepay.jpg" alt="PhonePe QR Code" className="img-fluid qr-code" />
                      </div>
                      <div className="qr-item">
                        <p>Google Pay QR</p>
                        <img src="img/gpay.jpg" alt="Google Pay QR Code" className="img-fluid qr-code" />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PayNow;
