function ContactC(){
    return(
        <>
            <div className="main-wrapper">
            <div className="container-fluid py-5 mb-5">
            <div className="container">
                <div className="text-center mx-auto pb-5 wow fadeIn" data-wow-delay=".3s" style={{maxWidth: '600px'}}>
                    <h5 className="text-primary">Get In Touch</h5>
                    <h1 className="mb-3">Contact for any query</h1>
                    <p className="mb-2">Our team is ready to assist you with any inquiries you may have. Feel free to contact us via phone, email, or by filling out the form below.</p> 
                </div>
                <div className="contact-detail position-relative p-4">
                    <div className="row g-5 mb-5 justify-content-center">
                        <div className="col-xl-4 col-lg-6 wow fadeIn" data-wow-delay=".3s">
                            <div className="d-flex bg-light p-3 rounded">
                                <div className="flex-shrink-0 btn-square bg-secondary rounded-circle" style={{width: '64px', height: '64px'}}>
                                    <i className="fas fa-map-marker-alt text-white"></i>
                                </div>
                                <div className="ms-3">
                                    <h4 className="text-primary">Address</h4>
                                    {/* <a href="#" target="_blank" className="h5">E45 krishna Park Khanpur Devli, New Delhi - 110062</a> */}
                                    <a href="javascript:void(0)" target="_self" className="h6">E45 krishna Park Khanpur Devli, New Delhi - 110062</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-6 wow fadeIn" data-wow-delay=".5s">
                            <div className="d-flex bg-light p-3 rounded">
                                <div className="flex-shrink-0 btn-square bg-secondary rounded-circle" style={{width: '64px', height: '64px'}}>
                                    <i className="fa fa-phone text-white"></i>
                                </div>
                                <div className="ms-3">
                                    <h4 className="text-primary">Call Us</h4>
                                    <a className="h6" href="tel:+91 7992249312" target="_blank">+91 7992249312</a><br/><br/> 
                                </div> 
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-6 wow fadeIn" data-wow-delay=".7s">
                            <div className="d-flex bg-light p-3 rounded">
                                <div className="flex-shrink-0 btn-square bg-secondary rounded-circle" style={{width: '64px', height: '64px'}}>
                                    <i className="fa fa-envelope text-white"></i>
                                </div>
                                <div className="ms-3">
                                    <h4 className="text-primary">Email Us</h4>
                                    <a className="h6" href="mailto:info@codeitsolutionshub.com" target="_blank">info@codeitsolutionshub.com</a><br/><br/> 
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row g-5">
                        <div className="col-lg-6 wow fadeIn" data-wow-delay=".3s">
                            <div className="p-5 h-100 rounded contact-map">
                                {/* <iframe className="rounded w-100 h-100" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3025.4710403339755!2d-73.82241512404069!3d40.685622471397615!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c26749046ee14f%3A0xea672968476d962c!2s123rd%20St%2C%20Queens%2C%20NY%2C%20USA!5e0!3m2!1sen!2sbd!4v1686493221834!5m2!1sen!2sbd"
                                    style={{border:'0'}}  loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}

                        <iframe className="rounded w-100 h-100" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3505.995902640064!2d77.23377169999999!3d28.509770999999994!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce1a20e94f69d%3A0xf6c5af89da672917!2sCode%20IT%20Solutions!5e0!3m2!1sen!2sin!4v1735127594492!5m2!1sen!2sin" width="600" height="450" style={{border:'0'}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                            </div>  
                        </div>
                        <div className="col-lg-6 wow fadeIn" data-wow-delay=".5s"> 
                            <div className="p-5 rounded contact-form">
                                <form method="POST">
                                <div className="mb-4">
                                    <label htmlFor="name" className="form-label text-white-50">Name <span className="text-danger">*</span></label>
                                    <input type="text" className="form-control border-0 py-3" placeholder="Your Name" required="required" />
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="email" className="form-label text-white-50">Email <span className="text-danger">*</span></label>
                                    <input type="email" className="form-control border-0 py-3" placeholder="Your Email" required="required" />
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="phone" className="form-label text-white-50">Phone <span className="text-danger">*</span></label>
                                    <input type="text" className="form-control border-0 py-3" placeholder="Your Phone" required="required" />
                                </div> 
                                <div className="mb-4">
                                    <label htmlFor="project" className="form-label text-white-50">Tell us about your project</label>
                                    <textarea className="w-100 form-control border-0 py-3" rows="3" cols="6" placeholder="Message"></textarea>
                                </div>

                                <div className="mb-4">
                                    <div className="position-relative mb-3 badge_wrap">
                                    <em className="badge-icon">
                                        <img src="img/security-badge.svg" alt="security badge" width={23} height={28} /></em>
                                        <p className="text-white-50">Your Ideas are Fully Protected By Our <strong>Non Disclosure Agreement (NDA)</strong></p>
                                    </div> 
                                </div>

                                {/* <div className="mb-4">
                                    <p className="text-white">Robot Captcha Here</p> 
                                </div>  */} 

                                <div className="text-start">
                                    {/* <button type="submit" className="btn bg-warning text-white py-2 px-4">Send Message</button> */}

                                    <input type="submit" className="btn bg-warning text-white py-2 px-4" />
                                </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
        </>
    );
}

export default ContactC;